import React from "react";
import { ContentContainer, Title } from "./Abonnement";
import { useGetAllOrdersQuery } from "../store/api/orders";
import {
	Chip,
	Paper,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
} from "@mui/material";
import moment from "moment";
import PriceFormater from "../components/PriceFormater";
import InexButton from "../components/InexButton";
import { useSelector } from "react-redux";
import { getUser } from "../store/reducers/authSlice";
import withAuth from "../HOC/withAuth";
import useTabTitle from "../hooks/useTabTitle";
import SelectAddressForOrderPDF from "../components/orders/SelectAddressForOrderPDF";
import SmartsuppChat from "../components/SmartsuppChat";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	paddingTop: 6,
	paddingBottom: 6,
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const Orders = () => {
	const [openModalForAddress, setOpenModalForAddress] = React.useState(false);

	useTabTitle({ customTitle: "Mes commandes" });

	const user = useSelector(getUser);

	const { data, isFetching } = useGetAllOrdersQuery(
		{},
		{
			skip: !user,
		}
	);
	const [orders, setOrders] = React.useState(null);

	React.useEffect(() => {
		if (data) {
			const orders = data.commandes;
			const old = data.oldcommandes ?? [];
			setOrders(orders.concat(old));
		}
	}, [data]);

	const tableCellSX = {
		fontFamily: "Branding Bold",
		fontSize: 16,
	};

	return (
		<ContentContainer container item className={"content-background"}>
			<Title
				style={{
					marginBottom: 15,
				}}
			>
				Mes commandes
			</Title>
			{!orders ? (
				<Skeleton
					width={"100%"}
					height={"200px"}
					sx={{
						backgroundColor: "white",
						boxShadow: (theme) => theme.shadows[25],
						borderRadius: "8px",
					}}
					variant="rectangular"
					animation={"pulse"}
				/>
			) : (
				<TableContainer component={Paper}>
					<Table stickyHeader aria-label="simple table">
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: (theme) => theme.palette.grey[300],
								}}
							>
								<TableCell sx={tableCellSX}>Référence</TableCell>
								<TableCell sx={tableCellSX}>Montant</TableCell>
								<TableCell sx={tableCellSX}>Type de paiement</TableCell>
								<TableCell sx={tableCellSX}>Date de paiement</TableCell>
								<TableCell sx={tableCellSX} align={"center"}>
									Statut
								</TableCell>
								{/*<TableCell sx={tableCellSX}></TableCell>*/}
							</TableRow>
						</TableHead>
						<TableBody>
							{orders.map((row, index) => {
								let dateString;
								if (row.datePaid || row.creation) {
									dateString = moment
										.unix(row.datePaid ? row.datePaid : row.creation)
										.format("DD/MM/YYYY");
								} else {
									dateString = moment.unix(row.datepaie).format("DD/MM/YYYY");
								}
								return (
									<StyledTableRow
										key={index}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<StyledTableCell component="th" scope="row">
											<Typography
												sx={{
													textTransform: "uppercase",
													fontWeight: 600,
												}}
											>
												{row.reference}
											</Typography>
										</StyledTableCell>
										<StyledTableCell>
											<PriceFormater
												price={row.total ? row.total : row.montant}
												withSign={false}
												withTitle={false}
												withTaxes={false}
												withTimeFrame={false}
												priceProps={{
													sx: {
														fontSize: "0.875rem",
														fontFamily: "Branding SemiBold",
													},
												}}
											/>
										</StyledTableCell>
										<StyledTableCell>
											<Typography fontWeight={600} fontSize={14}>
												{row.typepaiement !== undefined
													? row.typepaiement === 2
														? "Carte Bancaire"
														: row.typepaiement === 3
														? "Prélèvement SEPA"
														: "Chèque"
													: "/"}
											</Typography>
										</StyledTableCell>
										<StyledTableCell>
											<Typography fontWeight={600} fontSize={14}>
												{dateString}
											</Typography>
										</StyledTableCell>
										<StyledTableCell align={"center"} width={"100px"}>
											{row.paid === undefined || row.paid === 1 ? (
												<Chip
													sx={{ fontWeight: 600 }}
													label="Payée"
													color="success"
													variant="outlined"
												/>
											) : (
												<Chip
													sx={{ fontWeight: 600 }}
													label="Impayée"
													color="error"
													variant="outlined"
												/>
											)}
										</StyledTableCell>
										<StyledTableCell align={"center"} width={"200px"}>
											<InexButton
												variant={"text"}
												textWithGradient={false}
												text={"Télécharger la facture"}
												onClick={() => {
													if (!row.idcommande) {
														window.open(row.lien).focus();
													} else {
														setOpenModalForAddress(row);
													}
												}}
												textSx={{
													fontSize: 14,
													color: (theme) => theme.palette.secondary.main,
												}}
												sx={{
													width: 200,
													height: 40,
													padding: 0,
													background: "transparent",
													borderColor: (theme) => theme.palette.secondary.main,
													"&:hover": {
														backgroundColor: "grey.300",
														borderColor: (theme) => theme.palette.secondary.main,
													},
												}}
											/>
										</StyledTableCell>
									</StyledTableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<SelectAddressForOrderPDF
				open={openModalForAddress}
				onClose={(event, row, address) => {
					setOpenModalForAddress(false);
					if (row != null) {
						if (address) {
							window
								.open(
									`${window.origin}/commande?id=${row.idcommande}&address=${JSON.stringify(address)}`,
									"_blank"
								)
								.focus();
						} else {
							window.open(`${window.origin}/commande?id=${row.idcommande}`, "_blank").focus();
						}
					}
				}}
			/>
			{/* Intégration de Smartsupp */}
			<SmartsuppChat />
		</ContentContainer>
	);
};

export default withAuth(Orders);
