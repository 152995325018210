import { styled, Typography } from "@mui/material";

const FormatedText = styled(Typography)(() => {
	return {
		color: "#ffffff !important",
		fontFamily: "Branding",
		"* > *": { color: "#ffffff !important", fontFamily: "Branding" },
		strong: { fontWeight: 600 },
		em: { fontStyle: "italic" },
	};
});

const Text = ({ block }) => {
	return <FormatedText dangerouslySetInnerHTML={{ __html: block.contenu }} />;
};

export default Text;
