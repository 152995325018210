import { Box, styled, Typography } from "@mui/material";
import { GradientTypo } from "../../../header/Header";
import React from "react";
import { computeStepsBeforeModuleIndex } from "../../../../context/odyssee";
import { ReactComponent as Done } from "../../../../resources/images/svg/odyssee/done.svg";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useOdysseeTheme } from "../../../../context/odysseeTheme/OdysseeThemeProvider";
import { computeHexOpacity, rgba2hex } from "../../../../utils/hexaOpacity";

const Container = styled(Box, {
	shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isDone" && prop !== "backgroundColor",
})(({ isActive, isDone, backgroundColor }) => {
	let props = isActive
		? {
				"&:after": {
					content: "''",
					position: "absolute",
					inset: 0,
					borderRadius: "10px",
					padding: "2px",
					background: "linear-gradient(90deg, #EE7992 20%, #F1AE65 50.51%, #F4C54D 100%)",
					mask: "linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0)",
					maskComposite: "exclude",
					pointerEvents: "none",
				},
		  }
		: {};
	return {
		backgroundColor: "#FFFFFF0F",
		borderRadius: 10,
		padding: "15px 20px",
		display: "flex",
		justifyContent: "space-between",
		opacity: isDone ? 0.5 : 1,
		position: "relative",
		cursor: isActive || isDone ? "pointer" : "auto",
		transition: "all 0.3s ease",
		"&:hover": {
			backgroundColor: isActive || isDone ? computeHexOpacity(rgba2hex(backgroundColor), 70) : backgroundColor,
			opacity: isActive || isDone ? 1 : 0.5,
		},
		...props,
	};
});

const HomeSummaryStep = ({
	step,
	moduleIndex,
	stepIndex,
	progressModuleIndex,
	modules,
	databaseModuleIndex,
	databaseStepIndex,
	navigateToStep,
}) => {
	const initialOffset = React.useMemo(() => {
		return computeStepsBeforeModuleIndex(moduleIndex, modules);
	}, [progressModuleIndex, modules]);

	const isActive = React.useMemo(() => {
		return stepIndex === databaseStepIndex && step?.idetape === modules[databaseModuleIndex]?.id;
	}, [databaseStepIndex, stepIndex]);

	const isDone = React.useMemo(() => {
		if (moduleIndex < databaseModuleIndex) return true;
		return moduleIndex === databaseModuleIndex && stepIndex < databaseStepIndex;
	}, [moduleIndex, databaseModuleIndex, databaseStepIndex]);

	const [backgroundColor] = useOdysseeTheme("background", ["primary"]);

	return (
		<Container
			isDone={isDone}
			isActive={isActive}
			backgroundColor={backgroundColor}
			onClick={() => {
				if (isActive || isDone) {
					navigateToStep(step?.idetape, step.id);
				}
			}}
		>
			<Box>
				{!!isDone ? (
					<Typography color={"#ffffff"} fontFamily={"Branding Bold"} fontSize={20}>{`ÉTAPE ${
						stepIndex + 1 + initialOffset
					}`}</Typography>
				) : (
					<GradientTypo fontSize={20}>{`ÉTAPE ${stepIndex + 1 + initialOffset}`}</GradientTypo>
				)}
				<Typography color={"#ffffff"} fontFamily={"Branding SemiBold"} fontSize={18}>
					{step.titre}
				</Typography>
			</Box>
			{!!isDone && <Done style={{ minWidth: 40, alignSelf: "flex-end" }} />}
			{!!isActive && (
				<PlayCircleIcon style={{ fontSize: 44, minWidth: 40, alignSelf: "flex-end", color: "white" }} />
			)}
		</Container>
	);
};

export default HomeSummaryStep;
