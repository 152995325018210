import React from "react";
import { Box, Dialog, Grid, styled, Typography } from "@mui/material";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ReactComponent as BookMarkIcon } from "../../../../resources/images/svg/odyssee/goFurther/bookmark.svg";
import { ReactComponent as FilmIcon } from "../../../../resources/images/svg/odyssee/goFurther/film.svg";
import Collection from "../../OdysseeBlocs/WebContent/Collection";
import CloseIcon from "../../../../resources/images/svg/close-white.svg";
import { useLazyGetOdysseeWebRessourceQuery } from "../../../../store/api/odyssee";
import useOdyssee from "../../../../context/odyssee/useOdyssee";
import { useAuthToken } from "../../../../hooks/auth";

const Container = styled(Grid)(({ theme }) => ({
	backgroundColor: "#FFFFFF1A",
	borderRadius: 20,
	padding: 16,
	position: "relative",
	maxWidth: "min(1800px, 90vw)",
	width: "100%",
}));

const CardContainer = styled(Box)(({ theme }) => ({
	width: "100%",
	position: "relative",
	cursor: "pointer",
	borderRadius: 20,
	overflow: "hidden",
	height: "min(320px, 50vw)",
	"&::after": {
		content: "''",
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
		height: 150,
		background: "linear-gradient(358.53deg, rgba(24, 18, 45, 0.8) 20.01%, rgba(24, 18, 45, 0) 99.1%)",
		borderBottomLeftRadius: 20,
		borderBottomRightRadius: 20,
		zIndex: 0,
	},
	"&:hover": {
		img: {
			transform: "scale(1.1)",
		},
	},
}));

const CardInfo = styled(Box)(({ theme }) => ({
	position: "absolute",
	bottom: 0,
	left: 0,
	display: "flex",
	gap: 10,
	padding: 10,
	zIndex: 2,
}));

const StyledImage = styled("img")(({ theme }) => ({
	aspectRatio: 312 / 323,
	width: "100%",
	objectFit: "cover",
	borderRadius: 20,
	transition: "all 0.3s ease",
	height: "min(320px, 50vw)",
}));

const SelectionCard = ({ collectionItem, openWebContentModal }) => {
	return (
		<CardContainer
			sx={{}}
			onClick={() => {
				openWebContentModal(collectionItem);
			}}
		>
			<StyledImage src={collectionItem?.item?.image} style={{}} />
			<CardInfo>
				{collectionItem?.category === "collectionFilms" ? <FilmIcon /> : <BookMarkIcon />}

				<Box>
					<Typography
						fontWeight={600}
						color={"#ffffff"}
						fontSize={16}
						height={50}
						sx={{
							display: "-webkit-box",
							overflow: "hidden",
							WebkitBoxOrient: "vertical",
							WebkitLineClamp: 2,
						}}
					>
						{collectionItem?.item?.titre}
					</Typography>
					<Typography
						fontWeight={500}
						height={30}
						color={"rgba(255,255,255,0.5)"}
						fontSize={15}
						sx={{
							display: "-webkit-box",
							overflow: "hidden",
							WebkitBoxOrient: "vertical",
							WebkitLineClamp: 1,
						}}
					>
						{collectionItem?.item?.description}
					</Typography>
				</Box>
			</CardInfo>
		</CardContainer>
	);
};

const SelectionCards = ({ collectionsJson }) => {
	const [modalOpen, setModalOpen] = React.useState(false);
	const [selectedCollection, setSelectedCollection] = React.useState(null);
	const [webRessource, setWebRessource] = React.useState(null);
	const [categoryCart, setCategoryCart] = React.useState("books");
	const { odyssee } = useOdyssee();
	const authToken = useAuthToken();
	const [fetchWebRessource] = useLazyGetOdysseeWebRessourceQuery();

	const collection = React.useMemo(() => {
		if (!collectionsJson) return [];
		return JSON.parse(collectionsJson);
	}, [collectionsJson]);

	const handleClose = (event) => {
		event.stopPropagation();
		setModalOpen(false);
	};

	const openWebContentModal = (collection) => {
		setSelectedCollection(collection);
		setCategoryCart(collection?.category === "collectionFilms" ? "movies" : "books");
		
		fetchWebRessource({
			odysseeId: odyssee?.id,
			authToken,
			categorie: collection?.category,
			ressourceId: collection?.item.id,
		}).then(({ data }) => {
			if (data) {
				setWebRessource(data);
				setModalOpen(true);
			}
		});
	};

	return (
		<Container>
			<Typography color={"#ffffff"} fontSize={{ xs: 22, md: 28 }} fontWeight={700} mb={3} lineHeight={1.2}>
				Sélection de livres et de films en lien avec votre odyssée
			</Typography>
			<Swiper
				slidesPerView={"auto"}
				spaceBetween={30}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Pagination, Navigation]}
				className="odyssee-go-further-swiper"
				slidesOffsetBefore={0}
				slidesOffsetAfter={30}
				style={{ cursor: "grab", paddingBottom: 60 }}
				initialSlide={0}
			>
				{collection?.map((collectionItem, index) => (
					<SwiperSlide key={`collection-${collectionItem?.item?.id}-${index}`}>
						<SelectionCard collectionItem={collectionItem} openWebContentModal={openWebContentModal} />
					</SwiperSlide>
				))}
			</Swiper>
			<Dialog
				open={modalOpen}
				onClose={handleClose}
				fullWidth={true}
				maxWidth={"xl"}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "0 0 30px 5px rgb(255, 255, 255, 0.6)",
						borderRadius: 10,
					},
				}}
				slotProps={{
					backdrop: {
						sx: {
							backgroundColor: "rgba(0, 0, 0, 0.9)",
						},
					},
				}}
			>
				<Box
					sx={{
						backgroundColor: "rgba(255,255,255,0.4)",
						width: 40,
						height: 40,
						position: "absolute",
						right: 10,
						top: 10,
						borderRadius: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
						zIndex: 10,
					}}
					onClick={handleClose}
				>
					<img style={{ width: "25px" }} src={CloseIcon} alt={"burger-menu"} />
				</Box>
				<Collection webContent={webRessource} category={categoryCart}  />
			</Dialog>
		</Container>
	);
};

export default SelectionCards;
