import React from "react";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

const LinearProgressOdyssee = styled(LinearProgress)(({ theme }) => ({
	height: 8,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: "#C7C4DE",
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		background: "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)",
	},
}));

export default LinearProgressOdyssee;
