import React from "react";
import InexCard from "../../InexCard";
import OdysseeContextProvider from "../../../context/odyssee";
import { OdysseeContext } from "../../../context/odyssee/context";
import { Box, Grid, Skeleton, styled, Typography } from "@mui/material";
import moment from "moment";
import InexButton from "../../InexButton";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import LinearProgressOdyssee from "../OdysseeHome/LinearProgressOdyssee";
import ProgressOdyssee, { useOdysseeProgress } from "../OdysseeHome/ProgressOdyssee";
import OdysseeThemeProvider, { useOdysseeTheme } from "../../../context/odysseeTheme/OdysseeThemeProvider";

const BoxTitle = styled(Typography)(({ theme, color }) => {
	return {
		fontFamily: "Branding Bold",
		fontSize: "27px",
		marginBottom: theme.spacing(2),
	};
});

const DateText = () => {
	const { odyssee } = useOdyssee();

	const isOut = React.useMemo(() => {
		const now = moment();
		const publicationDate = moment.unix(odyssee.publication);
		return now.isSameOrAfter(publicationDate);
	}, [odyssee]);

	const startDate = React.useMemo(() => {
		return odyssee ? moment.unix(odyssee.datedebut).format("DD/MM/YYYY") : null;
	}, [odyssee]);

	const publicationDate = React.useMemo(() => {
		return odyssee ? moment.unix(odyssee.publication).format("DD/MM/YYYY") : null;
	}, [odyssee]);

	const cardTheme = useOdysseeTheme("card");

	const textColor = React.useMemo(() => {
		return cardTheme?.header?.title;
	}, [cardTheme]);

	const style = {
		fontFamily: "Branding Medium",
		color: textColor,
	};

	return React.useMemo(() => {
		if (isOut) {
			if (odyssee.datedebut) {
				return <Typography sx={style}>{`Commencée le ${startDate}`}</Typography>;
			} else {
				return <Typography sx={style}>{`Votre nouvelle odyssée est disponible !`}</Typography>;
			}
		} else {
			return <Typography sx={style}>{`Disponible à partir du ${publicationDate}`}</Typography>;
		}
	}, [isOut, publicationDate, startDate]);
};

const _OdysseeDesktopCard = ({ odyssee, ...props }) => {
	const [positionSave, setPositionSave] = React.useState(0);

	React.useEffect(() => {
		setPositionSave(odyssee?.etape);
	}, [odyssee]);

	const cardTheme = useOdysseeTheme("card");

	const textColor = React.useMemo(() => {
		return cardTheme?.header?.title;
	}, [cardTheme]);

	const isOut = React.useMemo(() => {
		const now = moment();
		const publicationDate = moment.unix(odyssee?.publication);
		return now.isSameOrAfter(publicationDate);
	}, [odyssee]);

	return (
		<a
			href={odyssee && !!isOut ? `/odyssee/${odyssee.id}` : ""}
			target={odyssee && !!isOut ? "_blank" : ""}
			style={{
				textDecoration: "none",
			}}
		>
			<InexCard
				sx={{
					padding: (theme) => theme.spacing(0),
					paddingTop: (theme) => theme.spacing(0),
					paddingBottom: (theme) => theme.spacing(0),
					borderRadius: "20px",
					overflow: "hidden",
					width: "100%",
					maxWidth: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					background: cardTheme?.background,
				}}
			>
				<Box
					sx={{
						maxWidth: "20%",
						minHeight: "280px",
					}}
				>
					{odyssee ? (
						<img
							src={`https://medias.inrees.com/img/odyssees/1_${odyssee.id}.jpg`}
							alt="odyseeImage"
							draggable="false"
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					) : (
						<Skeleton variant="rect" sx={{ width: "100%", height: "235px" }} />
					)}
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						width: "100%",
						padding: (theme) => theme.spacing(3),
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "space-between",
							flexDirection: "column",
						}}
					>
						<Box>
							{odyssee ? (
								<DateText />
							) : (
								<Skeleton
									variant="rect"
									sx={{ width: "80%", height: "24px", borderRadius: (theme) => theme.spacing(1) }}
								/>
							)}
						</Box>
						<Box pt={2}>
							{odyssee ? (
								<BoxTitle color={textColor}>{odyssee?.titre}</BoxTitle>
							) : (
								<Skeleton
									variant="rect"
									sx={{
										width: "100%",
										height: "66px",
										borderRadius: (theme) => theme.spacing(1),
									}}
								/>
							)}
						</Box>
						{!!isOut && (
							<Box pt={1.5}>
								<InexButton
									text={positionSave === 0 ? "Commencer mon odyssée" : "Poursuivre mon odyssée"}
									variant={"text"}
									textWithGradient={false}
									sx={{
										width: "100%",
										paddingRight: (theme) => theme.spacing(4),
										paddingLeft: (theme) => theme.spacing(4),
										background: cardTheme?.buttons?.primary?.background,
										border: "none",
										"& > p": {
											color: cardTheme?.buttons?.primary?.textColor,
										},
										"&:hover": {
											backgroundColor: cardTheme?.buttons?.primary?.hover?.background,
											border: "none",
											"& > p": {
												color: cardTheme?.buttons?.primary?.hover?.textColor,
											},
										},
									}}
								/>
							</Box>
						)}
					</Box>
					<Box sx={{ marginBottom: "8px" }}>
						<ProgressOdyssee sx={{}} />
					</Box>
				</Box>
			</InexCard>
		</a>
	);
};

const _OdysseeMobileCard = ({ odyssee, ...props }) => {
	const { savedStepPosition } = useOdyssee();

	const { totalSteps, currentStep, progress } = useOdysseeProgress();

	const isOut = React.useMemo(() => {
		const now = moment();
		const publicationDate = moment.unix(odyssee?.publication);
		return now.isSameOrAfter(publicationDate);
	}, [odyssee]);

	return (
		<a
			href={odyssee && !!isOut ? `/odyssee/${odyssee.id}` : ""}
			target={odyssee && !!isOut ? "_blank" : ""}
			style={{
				textDecoration: "none",
			}}
		>
			<InexCard
				sx={{
					padding: (theme) => theme.spacing(0),
					paddingTop: (theme) => theme.spacing(0),
					paddingBottom: (theme) => theme.spacing(0),
					borderRadius: "20px",
					overflow: "hidden",
					width: "100%",
					maxWidth: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					background: "linear-gradient(183.02deg, #18122C 8.08%, #402338 96.44%)",
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						{odyssee ? (
							<img
								src={`https://medias.inrees.com/img/odyssees/1_${odyssee.id}.jpg`}
								alt="odyseeImage"
								draggable="false"
								style={{
									width: "100%",
									height: "100%",
									maxHeight: "300px",
									objectFit: "cover",
								}}
							/>
						) : (
							<Skeleton variant="rect" sx={{ width: "100%", height: "235px" }} />
						)}
					</Grid>
					<Grid item xs={12} container p={3}>
						<Grid item xs={12}>
							{odyssee ? (
								<DateText />
							) : (
								<Skeleton
									variant="rect"
									sx={{ width: "80%", height: "24px", borderRadius: (theme) => theme.spacing(1) }}
								/>
							)}
						</Grid>
						<Grid item xs={12} pt={2}>
							{odyssee ? (
								<BoxTitle>{odyssee?.titre}</BoxTitle>
							) : (
								<Skeleton
									variant="rect"
									sx={{ width: "100%", height: "66px", borderRadius: (theme) => theme.spacing(1) }}
								/>
							)}
						</Grid>
						<Grid item xs={12} pb={2} pt={2}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
									mb: (theme) => theme.spacing(1),
								}}
							>
								<Typography
									sx={{
										fontFamily: "Branding Medium",
										color: "white",
										fontSize: "14px",
									}}
								>
									{savedStepPosition === 0 ? `À réaliser` : `${progress.toFixed(0)}% réalisés`}
								</Typography>
								<Typography
									sx={{
										fontFamily: "Branding Medium",
										color: "#FFFFFF66",
										fontSize: "14px",
									}}
								>{`${currentStep} étapes sur ${totalSteps}`}</Typography>
							</Box>
							<LinearProgressOdyssee
								variant={"determinate"}
								value={Math.max(0, Math.min(progress, 100))}
							/>
						</Grid>
						<Grid item xs={12} pt={1.5}>
							{!!isOut && (
								<InexButton
									text={savedStepPosition === 0 ? "Commencer mon odyssée" : "Poursuivre mon odyssée"}
									variant={"text"}
									textWithGradient={false}
									sx={{
										width: "100%",
										padding: 0,
										background: "#EF7D8F",
										border: "none",
										"& > p": {
											color: "white",
										},
										"&:hover": {
											backgroundColor: "white",
											"& > p": {
												color: "#EF7D8F",
											},
										},
									}}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</InexCard>
		</a>
	);
};

const withOdysseeContext = (WrappedComponent) => {
	return ({ idOdyssee, ...props }) => {
		return (
			<OdysseeContextProvider idOdyssee={idOdyssee}>
				<OdysseeThemeProvider>
					<OdysseeContext.Consumer>
						{({ odyssee, ...rest }) => {
							return <WrappedComponent odyssee={odyssee} {...props} {...rest} />;
						}}
					</OdysseeContext.Consumer>
				</OdysseeThemeProvider>
			</OdysseeContextProvider>
		);
	};
};

export const OdysseeDesktopCard = withOdysseeContext(_OdysseeDesktopCard);
export const OdysseeMobileCard = withOdysseeContext(_OdysseeMobileCard);
