import React from "react";
import { useGetOdysseeUserListQuery } from "../../store/api/odyssee";
import { ContentContainer, Title } from "../Abonnement";
import { Box, CircularProgress, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import withAuth from "../../HOC/withAuth";
import { useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";
import { OdysseeDesktopCard, OdysseeMobileCard } from "../../components/odysee/List/OdysseeCard";
import { ReactComponent as CompassDrafting } from "../../resources/images/svg/sidebar/compass_drafting.svg";
import InexButton from "../../components/InexButton";
import { useIsMobile, useIsTablet } from "../../hooks/responsive";

const OdysseeList = () => {
	const theme = useTheme();
	const user = useSelector(getUser);

	const { data: odyssees } = useGetOdysseeUserListQuery(
		{},
		{
			skip: !user,
		},
	);

	const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<ContentContainer className={"content-background"} sx={{ overflow: "auto" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-start",
					flexDirection: "row",
				}}
			>
				<Title>Mes odyssées</Title>
				<Box
					sx={{
						marginLeft: (theme) => theme.spacing(2),
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						background: (theme) => theme.palette.lightRed,
						borderRadius: "20px",
						paddingRight: (theme) => theme.spacing(2),
						paddingLeft: (theme) => theme.spacing(2),
						color: "white",
					}}
				>
					{odyssees ? (
						<Typography
							sx={{
								fontFamily: "Branding SemiBold",
								fontSize: "17px",
								color: "white",
							}}
						>
							{odyssees.length}
						</Typography>
					) : (
						<CircularProgress
							color="inherit"
							size={"15px"}
							sx={{
								marginTop: "5px",
								marginBottom: "5px",
							}}
						/>
					)}
				</Box>
			</Box>
			{!odyssees ? (
				<Skeleton
					width={"100%"}
					height={"200px"}
					sx={{
						backgroundColor: "white",
						boxShadow: (theme) => theme.shadows[25],
						borderRadius: "8px",
						marginTop: (theme) => theme.spacing(3),
					}}
					variant="rectangular"
					animation={"pulse"}
				/>
			) : (
				<Grid container sx={{ marginTop: (theme) => theme.spacing(2), overflow: "auto" }} spacing={3}>
					{odyssees.length < 1 && (
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
							mt={10}
							mb={22}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									width: 80,
									height: 80,
									borderRadius: "50%",
									overflow: "hidden",
									backgroundColor: "#FFFFFF80",
									boxShadow: (theme) => theme.shadows[25],
									svg: {
										"path.stroke": {
											stroke: (theme) => theme.palette.secondary.main,
										},
									},
								}}
							>
								<CompassDrafting width={40} height={40} />
							</Box>
							<Typography
								mt={5}
								sx={{
									fontFamily: "Branding Bold",
									fontSize: "20px",
								}}
							>{`Vous n’avez pas d’odyssée active pour le moment`}</Typography>
							<InexButton
								text={"Découvrir les odyssées"}
								variant={"text"}
								textWithGradient={false}
								onClick={() => {
									window.location = "https://www.inexplore.com/odyssee/ovni-vers-une-realite-extraterrestre";
								}}
								sx={{
									width: "220px",
									padding: 0,
									marginTop: theme.spacing(5),
									background: "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)",
									border: "none",
									"& > p": {
										color: "white",
									},
									"&:hover": {
										border: "none",
										background: "white",
										"& > p": {
											color: (theme) => theme.palette.lightRed,
										},
									},
								}}
							/>
						</Grid>
					)}
					{odyssees?.map((odyssee) => {
						if (isSmallScreen) {
							return (
								<Grid item xs={12} md={6} lg={6} xl={3}>
									<OdysseeMobileCard idOdyssee={odyssee.id} />
								</Grid>
							);
						}

						return (
							<Grid item xs={12}>
								<OdysseeDesktopCard idOdyssee={odyssee.id} />
							</Grid>
						);
					})}
				</Grid>
			)}
		</ContentContainer>
	);
};

export default withAuth(OdysseeList);
