import React from "react";
import useOdyssee from "../odyssee/useOdyssee";

const OdysseeThemeContext = React.createContext(null);

function getValue(obj, path) {
	if (!Array.isArray(path)) {
		throw new Error("Le chemin doit être un tableau.");
	}

	return path.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
}

export const useOdysseeTheme = (themeScope = undefined, path = undefined) => {
	const theme = React.useContext(OdysseeThemeContext);

	return React.useMemo(() => {
		if (!path) {
			return themeScope && theme?.[themeScope] ? theme[themeScope] : theme;
		}
		return path?.map((scope) => getValue(theme?.[themeScope], scope?.split(".")));
	}, [theme, themeScope, path]);
};

const OdysseeThemeProvider = ({ children }) => {
	const { odyssee } = useOdyssee();

	const oTheme = React.useMemo(() => {
		return JSON.parse(odyssee?.themeBuilder || "{}");
	}, [odyssee]);

	return <OdysseeThemeContext.Provider value={oTheme}>{children}</OdysseeThemeContext.Provider>;
};

export default OdysseeThemeProvider;
