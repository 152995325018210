import React from "react";
import { Box, IconButton, Link, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { styled } from "@mui/material/styles";
import { blue, blueGrey, lightBlue } from "@mui/material/colors";
import { computeHexOpacity } from "../../../utils/hexaOpacity";

const Container = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		gap: 2,
		alignItems: "center",
		cursor: "pointer",
		transition: "all 0.3s",
		position: "relative",
		borderRadius: 8,
		paddingTop: 8,
		paddingBottom: 8,
		paddingLeft: 8,
		paddingRight: 8,
		backgroundColor: computeHexOpacity(lightBlue[50], 10),
		overflow: "hidden",
		maxWidth: "86vw",
		"&:hover": {
			backgroundColor: computeHexOpacity(lightBlue[50], 30),
		},
	};
});

const Pdf = ({ block }) => {
	const openPdf = () => {
		window.open(block.source, "_blank");
	};

	return (
		<Container onClick={openPdf}>
			<IconButton aria-label="delete" onClick={openPdf} color={"primary"}>
				<LinkIcon sx={{ color: "#ffffff" }} />
			</IconButton>
			<Typography color={"#ffffff"} fontFamily={"Branding SemiBold"}>
				{block.contenu || block.source}
			</Typography>
		</Container>
	);
};

export default Pdf;
