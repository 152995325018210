import { Box, Skeleton, Stack, styled, Typography } from "@mui/material";
import React from "react";
import WebRessourceCard from "./WebRessourceCard";
import { useIsMobile } from "../../../../hooks/responsive";
import useOdyssee from "../../../../context/odyssee/useOdyssee";
import SelectionCards from "./SelectionCards";
import Partners from "./Partners";
import MoreOdyssees from "./MoreOdyssees";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import { motion } from "motion/react";

const HeaderContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isMobile" })(({ theme, isMobile }) => ({
	display: "flex",
	justifyContent: isMobile ? "center" : "space-between",
	marginBottom: theme.spacing(4),
	flexWrap: "wrap",
	alignItems: "center",
	gap: theme.spacing(6),
}));
const TagsContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	gap: 15,
	alignItems: "center",
}));
const Tag = styled(Box)(({ theme }) => ({
	display: "flex",
	backgroundColor: "#ffffff",
	borderRadius: 20,
	padding: "7px 16px",
	alignItems: "center",
	justifyContent: "center",
	cursor: "pointer",
}));

const GoFurther = ({ goFurther }) => {
	const isMobile = useIsMobile();

	const tags = React.useMemo(() => {
		if (!goFurther?.tagsJson) return [];
		return JSON.parse(goFurther?.tagsJson);
	}, [goFurther]);

	if (!goFurther)
		return (
			<Box sx={{ mt: 8, mb: 8, width: "50vw" }}>
				<Skeleton
					sx={{
						backgroundColor: "rgba(255,255,255,0.18)",
						borderRadius: "8px",
						marginTop: (theme) => theme.spacing(2),
					}}
					variant="rectangular"
					width={"300"}
					height={"350px"}
					animation={"pulse"}
				/>
				<Skeleton
					sx={{
						backgroundColor: "rgba(255,255,255,0.18)",
						borderRadius: "8px",
						marginTop: (theme) => theme.spacing(8),
					}}
					variant="rectangular"
					width={"300"}
					height={"350px"}
					animation={"pulse"}
				/>
			</Box>
		);

	return (
		<motion.div
			transition={{ type: "spring", duration: 1, delay: 0 }}
			initial={{ opacity: 0, scale: 0.95 }}
			animate={{ opacity: 1, scale: 1 }}
		>
			<Box sx={{ fontFamily: "Branding", mt: 8, mb: 8 }}>
				<HeaderContainer isMobile={isMobile}>
					<Typography variant={"h2"} color={"#ffffff"} fontWeight={700} fontSize={{ xs: 30, md: 37 }}>
						Pour aller plus loin
					</Typography>
					<TagsContainer>
						{tags?.map((tag) => (
							<Tag
								key={tag?.name}
								onClick={() => {
									window
										.open(`https://www.inexplore.com/tag/${tag?.name?.toLowerCase()}`, "_blank")
										.focus();
								}}
							>
								<Typography color={"#182B43"} fontWeight={600}>
									{tag?.name}
								</Typography>
							</Tag>
						))}
					</TagsContainer>
				</HeaderContainer>
				<Stack spacing={8} alignItems={"center"}>
					<WebRessourceCard ressourceJson={goFurther?.ressourceJson} />
					<SelectionCards collectionsJson={goFurther?.collectionsJson} />
					<Partners partnersJson={goFurther?.partenairesJson} />
					<MoreOdyssees />
				</Stack>
			</Box>
		</motion.div>
	);
};

export default GoFurther;
