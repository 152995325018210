import React from "react";
import { Box, Dialog, Grid, Stack, styled, Typography } from "@mui/material";
import { ReactComponent as GiftIcon } from "../../../../resources/images/svg/odyssee/gift.svg";
import { ReactComponent as SparklesIcon } from "../../../../resources/images/svg/odyssee/goFurther/sparkles.svg";
import { ReactComponent as CheckIcon } from "../../../../resources/images/svg/odyssee/goFurther/hexagon-check.svg";
import { ReactComponent as EyeIcon } from "../../../../resources/images/svg/odyssee/goFurther/eye.svg";
import InexButton from "../../../InexButton";
import { useIsMobile } from "../../../../hooks/responsive";
import CloseIcon from "../../../../resources/images/svg/close-white.svg";
import Collection from "../../OdysseeBlocs/WebContent/Collection";
import Film from "../../OdysseeBlocs/WebContent/Film";
import Article from "../../OdysseeBlocs/WebContent/Article";
import Podcast from "../../OdysseeBlocs/WebContent/Podcast";
import Video from "../../OdysseeBlocs/WebContent/Video";
import { useGetOdysseeWebRessourceQuery } from "../../../../store/api/odyssee";
import { useAuthToken } from "../../../../hooks/auth";
import useOdyssee from "../../../../context/odyssee/useOdyssee";

const Container = styled(Grid)(({ theme }) => ({
	backgroundColor: "#FFFFFF1A",
	borderRadius: 20,
	width: "100%",
	maxWidth: "min(1800px, 90vw)",
}));

const GiftContainer = styled(Box)(({ theme }) => ({
	backgroundColor: "#FBF0E533",
	display: "flex",
	alignItems: "center",
	gap: 10,
	padding: "5px 10px",
	width: "fit-content",
	marginTop: theme.spacing(4),
}));

const AdventureContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	gap: 10,
	alignItems: "center",
	marginLeft: 15,
}));

const WebRessourceCard = ({ ressourceJson }) => {
	const [modalOpen, setModalOpen] = React.useState(false);
	const isMobile = useIsMobile();
	const authToken = useAuthToken();
	const { odyssee } = useOdyssee();

	const ressource = React.useMemo(() => {
		if (!ressourceJson) return [];
		return JSON.parse(ressourceJson);
	}, [ressourceJson]);

	const meta = React.useMemo(() => {
		if (!ressource?.meta) return [];
		return JSON.parse(ressource?.meta);
	}, [ressource]);

	const handleClose = (event) => {
		event.stopPropagation();
		setModalOpen(false);
	};

	const { data: webContent } = useGetOdysseeWebRessourceQuery(
		{ odysseeId: odyssee?.id, authToken, categorie: meta?.category, ressourceId: meta?.item.id },
		{ skip: !authToken || !meta }
	);

	const RenderModalContent = () => {
		switch (ressource?.category) {
			case "film":
				return <Film webContent={webContent} />;
			case "article":
				return <Article webContent={webContent} handleClose={handleClose} />;
			case "podcast":
				return <Podcast webContent={webContent} />;
			case "video":
				return <Video webContent={webContent} />;
			case "collection":
				return <Collection webContent={webContent} />;
			case "collectionFilms":
				return <Collection webContent={webContent} category={"films"} />;
			default:
				return <Film webContent={webContent} />;
		}
	};

	return (
		<React.Fragment>
			<Container container columnSpacing={2}>
				<Grid
					container
					item
					xs={12}
					md={8}
					columnSpacing={2}
					sx={{ padding: isMobile ? "0 16px" : "16px 0px" }}
				>
					<Grid item xs={0} md={3.5} display={{ xs: "none", md: "flex" }}>
						<img
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
							src={ressource?.image}
							alt={"odyssee image de couverture"}
						/>
					</Grid>
					<Grid item xs={12} md={8.5}>
						<Stack spacing={3} mt={3}>
							<GiftContainer>
								<GiftIcon width={16} />
								<Typography color={"#ffffff"} fontWeight={600} fontSize={16}>
									Un contenu exclusif Inexploré TV offert avec votre odyssée
								</Typography>
							</GiftContainer>
							<Typography
								fontWeight={700}
								color={"#ffffff"}
								fontSize={{ xs: 26, md: 30 }}
								lineHeight={1.2}
							>
								{ressource?.title}
							</Typography>
							<Typography
								fontWeight={500}
								color={"#ffffff"}
								fontSize={14}
								dangerouslySetInnerHTML={{ __html: ressource?.description }}
							/>
							<InexButton
								text={"Découvrir maintenant"}
								textWithGradient={false}
								textSx={{
									color: "#182B43",
								}}
								sx={{
									width: "fit-content",
									backgroundColor: "#C7C4DE",
									transition: "background-color 0.3s ease",
									"&:hover": { backgroundColor: "#ffffff", border: "none" },
									padding: "7px 30px",
									alignSelf: { xs: "center", md: "flex-start" },
									marginBottom: { xs: "24px !important", md: 0 },
								}}
								onClick={() => {
									setModalOpen(true);
									// window.open(`https://tv.inexplore.com/video/${meta?.item?.url}`, "_blank").focus();
								}}
							/>
						</Stack>
					</Grid>
				</Grid>
				<Grid
					item
					md={4}
					xs={12}
					sx={{
						background: "linear-gradient(90.04deg, #061636 0.05%, #22112E 99.98%);",
						borderTopRightRadius: { xs: 0, md: 20 },
						borderBottomRightRadius: 20,
						borderBottomLeftRadius: { xs: 20, md: 0 },
					}}
				>
					<Box sx={{ display: "flex", justifyContent: "center", height: "100%", padding: "30px 0" }}>
						<Box
							sx={{
								display: "flex",
								alignItems: "flex-start",
								flexDirection: "column",
								height: "100%",
								justifyContent: "space-between",
								maxHeight: 390,
								minHeight: 320,
							}}
						>
							<Typography
								color={"#C7C4DE"}
								fontWeight={700}
								fontSize={{ xs: 26, md: 30 }}
								lineHeight={1.1}
							>
								Avec Inexploré, vivez...
							</Typography>
							<AdventureContainer>
								<SparklesIcon />
								<Typography color={"#ffffff"} fontWeight={600} fontSize={16}>
									Une aventure au coeur des mystères
								</Typography>
							</AdventureContainer>
							<AdventureContainer>
								<CheckIcon />
								<Typography color={"#ffffff"} fontWeight={600} fontSize={16}>
									Une expertise éprouvée
								</Typography>
							</AdventureContainer>
							<AdventureContainer>
								<EyeIcon />
								<Typography color={"#ffffff"} fontWeight={600} fontSize={16}>
									Une voie 360° de transformation
								</Typography>
							</AdventureContainer>
							<InexButton
								text={"Explorer nos abonnements"}
								textWithGradient={false}
								textSx={{
									color: "#182b43",
								}}
								sx={{
									width: "fit-content",
									backgroundColor: "#f1c555",
									transition: "background-color 0.3s ease",
									"&:hover": { backgroundColor: "rgba(241,197,85,.61)", border: "none" },
									padding: "7px 30px",
									border: "none",
									alignSelf: "center",
								}}
								onClick={() => {
									window.open(`https://www.inexplore.com/s-abonner`, "_blank").focus();
								}}
							/>
						</Box>
					</Box>
				</Grid>
			</Container>
			<Dialog
				open={modalOpen}
				onClose={handleClose}
				fullWidth={true}
				maxWidth={"xl"}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "0 0 30px 5px rgb(255, 255, 255, 0.6)",
						borderRadius: 10,
					},
				}}
				slotProps={{
					backdrop: {
						sx: {
							backgroundColor: "rgba(0, 0, 0, 0.9)",
						},
					},
				}}
			>
				<Box
					sx={{
						backgroundColor: "rgba(255,255,255,0.4)",
						width: 40,
						height: 40,
						position: "absolute",
						right: 10,
						top: 10,
						borderRadius: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
						zIndex: 10,
					}}
					onClick={handleClose}
				>
					<img style={{ width: "25px" }} src={CloseIcon} alt={"burger-menu"} />
				</Box>
				{RenderModalContent()}
			</Dialog>
		</React.Fragment>
	);
};

export default WebRessourceCard;
