import React from "react";
import { Box, Typography } from "@mui/material";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import { useOdysseeTheme } from "../../../context/odysseeTheme/OdysseeThemeProvider";

const GradientSVG = ({ idCSS }) => {
	const theme = useOdysseeTheme("odyssee");

	const gradient = React.useMemo(() => {
		return theme?.home?.progress?.gradient ?? null;
	}, [theme]);

	const gradientData = React.useMemo(() => {
		const result = {
			rotation: "0deg",
			breakpoints: [],
		};
		if (gradient) {
			const content = gradient.slice(16, gradient.length - 1);
			const arr = content.split(/, (?=[a-zA-Z])/);
			arr.forEach((element) => {
				if (element.indexOf("deg") !== -1) {
					result.rotation = element;
				} else {
					const bp = {};
					const tmp = element.split(/(?<=\)) /);
					tmp.forEach((el) => {
						if (el.indexOf("%") !== -1) bp.percent = el;
						else bp.color = el;
					});
					result.breakpoints.push(bp);
				}
			});
		}
		return result;
	}, [gradient]);

	return (
		<svg style={{ height: 0 }}>
			<defs>
				<linearGradient id={idCSS} gradientTransform={`rotate(${gradientData?.rotation})`}>
					{gradientData.breakpoints.map((breakpoint, index) => (
						<stop offset={breakpoint.percent} key={index} stopColor={breakpoint.color} />
					))}
				</linearGradient>
			</defs>
		</svg>
	);
};

export const useOdysseeProgress = () => {
	const { modules, databaseModuleIndex, databaseStepIndex } = useOdyssee();

	return React.useMemo(() => {
		if (!modules) return { totalSteps: 0, currentStep: 0, progress: 0 };
		let { totalSteps, currentStep } = modules?.reduce(
			(acc, curr, currIndex) => {
				if (currIndex === databaseModuleIndex) {
					acc.currentStep = acc.totalSteps + databaseStepIndex;
				}
				acc.totalSteps += curr.lecon.length;
				return acc;
			},
			{ totalSteps: 0, currentStep: 0 },
		);
		currentStep = currentStep > 0 ? currentStep + 1 : currentStep;
		return { progress: (currentStep * 100) / totalSteps, totalSteps, currentStep };
	}, [modules, databaseStepIndex, databaseModuleIndex]);
};

const ProgressOdyssee = ({ ...props }) => {
	const { savedStepPosition } = useOdyssee();
	let { totalSteps, currentStep, progress } = useOdysseeProgress();
	// totalSteps = 10;
	// currentStep = 5;
	// progress = currentStep / totalSteps * 100;

	const cardTheme = useOdysseeTheme("card");

	const textColor = React.useMemo(() => {
		return cardTheme?.header?.content;
	}, [cardTheme]);

	return (
		<Box {...props}>
			<GradientSVG idCSS={"odyssee-circle"} />
			<CircularProgressbarWithChildren value={progress} strokeWidth={5}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "100%",
					}}
				>
					<Typography
						color={textColor}
						fontFamily={"Branding Medium"}
						textAlign={"center"}
						mb={savedStepPosition === 0 ? 3 : 0}
					>
						{savedStepPosition === 0
							? `La progression de votre odyssée s’affichera ici`
							: `Vous avez réalisé`}
					</Typography>
					{savedStepPosition > 0 && (
						<>
							<Typography color={textColor} fontFamily={"Branding Bold"} fontSize={40}>
								{Math.round(progress)}%
							</Typography>
							<Typography color={textColor} fontFamily={"Branding Medium"}>
								de votre odyssée
							</Typography>
						</>
					)}
					<Typography
						color={textColor}
						fontFamily={"Branding Medium"}
						mt={savedStepPosition === 0 ? 3 : 0}
						sx={{
							opacity: "56%",
						}}
					>
						{currentStep} étapes sur {totalSteps}
					</Typography>
				</Box>
			</CircularProgressbarWithChildren>
		</Box>
	);
};

export default ProgressOdyssee;
