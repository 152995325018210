import { Box, styled, Typography } from "@mui/material";
import React from "react";

const Container = styled(Box)(() => {
	return {
		padding: 20,
		marginLeft: "3vw",
		marginRight: "3vw",
		minHeight: 600,
		height: "90vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		borderRadius: "20px",
		position: "relative",
		"&:after": {
			content: "''",
			position: "absolute",
			inset: 0,
			borderRadius: "20px",
			padding: "2px",
			background: "linear-gradient(45deg,rgba(255, 255, 255, 0.15),rgba(255, 255, 255, 0.02))",
			mask: "linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0)",
			maskComposite: "exclude",
			pointerEvents: "none",
		},
	};
});

const StepRightContainer = ({ currentStep }) => {
	const devUrl = React.useMemo(() => {
		if (window.location.hostname === "localhost") {
			// return "dev/";
			return "";
		}
		return "";
	}, []);

	const hasQuote = React.useMemo(() => {
		return !!currentStep?.citation;
	}, [currentStep]);

	const quote = React.useMemo(() => {
		return JSON.parse(!!currentStep?.citation ? currentStep?.citation : "{}") || {};
	}, [currentStep]);

	const renderQuote = () => (
		<Box>
			<Typography
				color={"#ffffff"}
				fontSize={29}
				textAlign={"end"}
				mb={4}
				fontFamily={"Branding Bold"}
				lineHeight={1.2}
			>
				{quote?.texte}
			</Typography>
			<Typography color={"#ffffff"} fontSize={20} textAlign={"end"} fontFamily={"Branding Bold"}>
			— {quote?.auteur}
			</Typography>
		</Box>
	);

	if (!hasQuote) {
		return (
			<Box>
				<img
					src={`https://medias.inrees.com/img/odyssees/${devUrl}etapes/1_${currentStep?.id}.jpg`}
					style={{ width: "100%", borderRadius: "20px" }}
				/>
			</Box>
		);
	}

	return <Container>{renderQuote()}</Container>;
};

export default StepRightContainer;
