import React from "react";
import OdysseeContextProvider from "../../context/odyssee";
import MinimalistHeader from "../../components/header/MinimalistHeader";
import withAuth from "../../HOC/withAuth";
import OdysseeHome from "../../components/odysee/OdysseeHome";
import OdysseeSummary from "../../components/odysee/OdysseeSummary";
import OdysseeStep from "../../components/odysee/OdysseeStep";

import { Box } from "@mui/material";
import OdysseeNavigationContextProvider, { ODYSSEE_PAGES } from "../../context/odysseeNavigation";
import useOdysseeNavigation from "../../context/odysseeNavigation/useOdyssee";
import useTabTitle from "../../hooks/useTabTitle";
import useOdyssee from "../../context/odyssee/useOdyssee";
import { useParams } from "react-router";
import OdysseeThemeProvider, { useOdysseeTheme } from "../../context/odysseeTheme/OdysseeThemeProvider";

const Odyssee = () => {
	const { currentPage, navigateToSummary } = useOdysseeNavigation();
	const { odysseeId } = useParams();
	const { odyssee } = useOdyssee();

	const cardTheme = useOdysseeTheme("background");

	React.useEffect(() => {
		if (odyssee !== undefined && odyssee === null) {
			window.location.replace(`https://odyssees.inexplore.com/${odysseeId}`);
		}
	}, [odyssee]);

	useTabTitle({ customTitle: `Votre odyssée - ${odyssee?.titre || ""}` });

	const background = React.useMemo(() => {
		return cardTheme?.primary;
	}, [cardTheme]);

	return (
		<Box sx={{ background, position: "relative" }}>
			<MinimalistHeader />
			{currentPage === ODYSSEE_PAGES.Main && <OdysseeHome />}
			{currentPage === ODYSSEE_PAGES.Summary && <OdysseeSummary />}
			{currentPage === ODYSSEE_PAGES.Step && <OdysseeStep />}
		</Box>
	);
};

const OdysseeManager = () => {
	return (
		<OdysseeContextProvider>
			<OdysseeThemeProvider>
				<OdysseeNavigationContextProvider>
					<Odyssee />
				</OdysseeNavigationContextProvider>
			</OdysseeThemeProvider>
		</OdysseeContextProvider>
	);
};

export default withAuth(OdysseeManager);
