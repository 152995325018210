import React, { useRef } from "react";
import { Box, styled, Typography } from "@mui/material";
import { useIsMobile } from "../../../hooks/responsive";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useNavigate, useParams } from "react-router-dom";

const Container = styled(Box, { shouldForwardProp: (prop) => prop !== "isMobile" })(({ isMobile }) => {
	return {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "#FFFFFE1A",
		borderRadius: 40,
		position: "relative",
		cursor: "pointer",
		zIndex: 2,
		maxWidth: "95%",
		overflow: "scroll",
		msOverflowStyle: "none" /* IE and Edge */,
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none", // Cache la scrollbar (Chrome/Safari)
		},
	};
});

const Item = styled(Box, { shouldForwardProp: (prop) => prop !== "isMobile" && prop !== "disabled" })(
	({ isMobile, disabled }) => {
		return {
			width: isMobile ? 150 : 200,
			height: 57,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: 40,
			cursor: disabled ? "auto" : "pointer",
		};
	}
);

const Indicator = styled(Box, { shouldForwardProp: (prop) => prop !== "isMobile" })(({ isMobile }) => {
	return {
		width: isMobile ? 150 : 200,
		height: 57,
		position: "absolute",
		borderRadius: 40,
		background: "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)",
		transition: "transform 0.3s ease",
	};
});

const MenuOdyssee = ({ elements, onClick }) => {
	const containerRef = useRef(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [isInitialized, setIsInitialized] = React.useState(false);
	const isMobile = useIsMobile();
	const { width: screenWidth } = useWindowDimensions();
	const navigate = useNavigate();
	const { odysseeId, section } = useParams();
	const ITEM_WIDTH = isMobile ? 150 : 200;

	const navigationUrlIndex = React.useMemo(() => {
		if (!section) return 0;
		return elements?.findIndex((elem) => elem?.url === section);
	}, [elements, section]);

	React.useEffect(() => {
		if (isNaN(navigationUrlIndex)) return;
		if (!!isInitialized) return;
		if (navigationUrlIndex !== selectedIndex) {
			setSelectedIndex(navigationUrlIndex);
			onClick?.({ ...elements[navigationUrlIndex], index: navigationUrlIndex });
			onItemClicked(navigationUrlIndex);
		}
		setIsInitialized(true);
	}, [navigationUrlIndex, selectedIndex, isInitialized]);

	const onItemClicked = (index) => {
		const url = elements[index]?.url;
		if (url) {
			navigate(`/odyssee/${odysseeId}/${url}`);
		} else {
			navigate(`/odyssee/${odysseeId}`);
		}
		if (isMobile) {
			const containerWidth = screenWidth * 0.95;
			containerRef.current?.scrollTo({
				behavior: "smooth",
				left: ITEM_WIDTH * index - (containerWidth / 2 - ITEM_WIDTH / 2),
			});
		}
		setSelectedIndex(index);
	};

	return (
		<Container isMobile={isMobile} ref={containerRef}>
			<Box sx={{ display: "flex", width: isMobile ? 150 * 4 : "100%" }}>
				<Indicator isMobile={isMobile} sx={{ transform: `translateX(${selectedIndex * ITEM_WIDTH}px)` }} />
				{elements.map((item, index) => (
					<Item
						isMobile={isMobile}
						disabled={item?.disabled}
						key={item.id}
						onClick={() => {
							if (!!item?.disabled) return;
							onClick?.({ ...item, index });
							onItemClicked(index);
						}}
					>
						<Typography
							sx={{
								position: "relative",
								zIndex: 3,
								transition: "color 0.3s ease",
								userSelect: "none",
								opacity: !!item?.disabled ? 0.5 : 1,
							}}
							fontFamily={"Branding SemiBold"}
							fontSize={18}
							color={index === selectedIndex ? "#182B43" : "#FFFFFF"}
						>
							{item?.name}
						</Typography>
					</Item>
				))}
			</Box>
		</Container>
	);
};

export default MenuOdyssee;
