import React from "react";
import { Typography } from "@mui/material";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Video = ({ block }) => {
	const divRef = React.useRef(null);
	const [editorWidth, setEditorWidth] = React.useState(0);
	const [title, setTitle] = React.useState(block.contenu || "");
	const { width } = useWindowDimensions();

	React.useEffect(() => {
		if (divRef.current?.offsetWidth) {
			setEditorWidth(divRef.current?.offsetWidth);
		}
	}, [divRef.current, block.source]);

	return (
		<div className="layoutRoot" ref={divRef}>
			<Typography> {title} </Typography>
			<iframe
				src={`https://player.vimeo.com/video/${block.source}?h=1da7fd76b8`}
				width={Math.min(width - 50, editorWidth) || 0}
				height="468"
				frameBorder="0"
				allow="autoplay; fullscreen; picture-in-picture"
				allowFullScreen
			></iframe>
		</div>
	);
};
export default Video;
