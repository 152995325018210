import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";

const Container = styled(Grid)(({ theme }) => ({
	backgroundColor: "#FFFFFF1A",
	borderRadius: 20,
	padding: 16,
	position: "relative",
	maxWidth: "min(1800px, 90vw)",
	width: "100%",
}));

const PartnerContainer = styled(Grid)(({ theme }) => ({
	backgroundColor: "#FFFFFF1A",
	borderRadius: 20,
	padding: 16,
	position: "relative",
}));

const Partners = ({ partnersJson }) => {
	const partners = React.useMemo(() => {
		if (!partnersJson) return [];
		return JSON.parse(partnersJson);
	}, [partnersJson]);

	return (
		<Container>
			<Typography color={"#ffffff"} fontSize={{ xs: 22, md: 28 }} fontWeight={700} mb={3}>
				Partenaires
			</Typography>
			<Grid container spacing={3}>
				{partners?.map((partner) => (
					<Grid sx={{}} item xs={12} md={6} key={partner?.name}>
						<Box
							sx={{
								display: "flex",
								gap: 5,
								backgroundColor: "#FFFFFF1A",
								borderRadius: "20px",
								padding: "15px 30px",
								cursor: "pointer"
							}}
							onClick={() => {
								window.open(`${partner?.lien}`, "_blank").focus();
							}}
						>
							<img src={partner?.url} style={{ height: 125, width: 125 }} />
							<Box>
								<Typography color={"#ffffff"} fontWeight={600} fontSize={16}>
									{partner?.name}
								</Typography>
								<Typography
									fontWeight={500}
									color={"rgba(255,255,255,0.5)"}
									fontSize={14}
									dangerouslySetInnerHTML={{ __html: partner?.description }}
								/>
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};

export default Partners;
